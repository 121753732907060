.projects {
  margin: 5rem 10rem 3rem 10rem;
  display: flex;
  flex-direction: column;
  align-items: center; /* aligin items on the middle of the div*/
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns with equal width */
  gap: 20px;
}

.project-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-description {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Responsive design for screens smaller than or equal to 768px */
@media only screen and (max-width: 768px) {
  .projects {
    /* Adjust the margins for smaller screens */
    margin: 2.5rem 5rem 1.5rem 5rem;
  }

  .projects-grid {
    /* Adjust the grid for smaller screens */
    grid-template-columns: repeat(1, 1fr); /* One column with full width */
  }
}
