.navbar {
  background-color: #212936;
  padding: 1rem;
  height: 2.5rem;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.navbar-items {
  display: flex;
  height: 3rem;
  padding: 0.8rem;
  width: 100%;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
}

.name-link {
  font-size: 20px;
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  margin-left: 8.5rem; /* Add left margin to shift "Prahlad Panthi" to the right */
  margin-right: 10px;
}

.navbar-separator {
  height: 50%; /* Adjust the height of the separator */
  width: 1px; /* Adjust the width of the separator */
  background-color: #394150; /* Color of the separator */
  margin: 0 5px; /* Adjust the spacing around the separator */
  display: flex;
  align-items: center;
}

.navbar-links {
  justify-items: center;
  margin-right: auto;
  margin-left: 1.5rem;
}

.hire {
  margin-right: 10.5rem;
  display: flex;
  align-items: center;
  height: auto;
}

.hire:hover {
  background-color: #394150;
  border-radius: 5px;
  transform: scale(1.05);
}

.hire-btn {
  height: 100%;
  display: block;
}

.hire-btn:hover {
  transform: scale(1.05);
}

a {
  text-decoration: none;
  color: #9da3ae;
  font-size: 16px;
  margin: 10px;
}

a:hover {
  color: white;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .name-link {
    font-size: 16px; /* Reduce font size for smaller screens */
  }

  .navbar-separator {
    display: none; /* Hide the separator on smaller screens */
  }

  .navbar-links {
    display: none; /* Hide the links on smaller screens */
  }

  .hire {
    margin-right: 0; /* Remove right margin on smaller screens */
  }
}
