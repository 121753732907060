/* need to make it responsive*/
.contact {
  margin: 3% 20% 0% 20%;
  display: flex;
  flex-direction: column;
  align-items: center; /* aligin items on the middle of the div*/
}

.contact-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3%;
}

.contact-form-paper {
  display: flex;
  flex-direction: column;
  margin: 0% 30% 10% 30%;
  justify-content: center;
  border-radius: 16px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .contact {
    margin: 3% 5% 0% 5%; /* Adjust margin for medium-sized screens */
  }

  .contact-form-paper {
    margin: 0% 5% 5% 5%; /* Adjust margins for medium-sized screens */
  }
}

@media (max-width: 480px) {
  .contact {
    margin: 3% 3% 0% 3%; /* Adjust margin for smaller screens */
  }

  .contact-form-paper {
    margin: 0% 0% 0% 3%; /* Adjust margins for smaller screens */
  }
}
