.about {
  display: flex;
  margin: 70px 50px 20px 50px;
}

.social-icons {
  display: flex;
}

.intro {
  margin-bottom: 2rem;
  color: #9ea3ad;
  line-height: 1.6rem;
  padding-right: 3rem;
}

.left-section {
  display: flex;
  flex-direction: column;
  margin: 130px 40px 20px 5px;
}

.right-section {
  display: flex;
  height: 200px;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .about {
    flex-direction: column;
    margin: 50px 20px;
  }

  .intro {
    padding-right: 1rem;
  }

  .left-section,
  .right-section {
    margin: 50px 20px;
  }
}
