.skills {
  margin: 3rem 10rem 3rem 10rem;
  display: flex;
  flex-direction: column;
  align-items: center; /* aligin items on the middle of the div*/
}

.skills-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.skills-grid {
  display: grid;
  margin-left: 10rem;
  margin-right: 10rem;
  grid-template-columns: repeat(3, 1fr); /* Two columns with equal width */
  gap: 20px;
}

.skill {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;
  background-color: #212936;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .skills {
    margin: 2rem;
  }

  .skills-grid {
    margin-left: 2rem;
    margin-right: 2rem;
    grid-template-columns: repeat(1, 1fr); /* One column for smaller screens */
  }
}
