body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
  font-family: "Open Sans", sans-serif;
  background-color: #121826;
}

.all-contents {
  display: flex;
  flex-direction: column;
}

.content-section {
  height: 100vh;
  margin-left: 3rem;
  margin-right: 3rem;
  margin-top: 4.5rem;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 1rem;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 5rem;
}

.app {
  height: 100vh;
  background-color: #121826;
}

.projects-section {
  margin-top: 5rem;
}

/* Responsive design for screens smaller than or equal to 768px */
@media only screen and (max-width: 768px) {
  .content-section {
    /* Adjust the margins and padding for smaller screens */
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;

    /* Adjust the gap for smaller screens */
    gap: 2rem;
  }

  .projects-section {
    /* Adjust the top margin for smaller screens */
    margin-top: 2.5rem;
  }
}
